import "../styles/Authenticator.css";
import React, { useState, useEffect } from "react";
import ForgotPasswordPanel from "./ForgotPasswordPanel";
import TabbedAuthentication from "./TabbedAuthentication";
import CreateAccountSuccess from "./CreateAccountSuccess";
import loadingGif from "../../img/magInstsLoading.gif";

const Authenticator = () => {
  window.scrollTo(0, 0);

  const [creatingAccount, setCreatingAccount] = useState(false);
  const [accountCreated, setAccountCreated] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [tabbedContentType, setTabbedContentType] = useState("SignIn");
  const [createAccountErrorMsg, setCreateAccountErrorMsg] = useState("");

  const [visibleContent, setVisibleContent] = useState(
    <TabbedAuthentication
      setForgotPassword={setForgotPassword}
      setTabbedContentType={setTabbedContentType}
      setCreatingAccount={setCreatingAccount}
      setAccountCreated={setAccountCreated}
      setCreateAccountErrorMsg={setCreateAccountErrorMsg}
      createAccountErrorMsg={createAccountErrorMsg}
      contentType={tabbedContentType}
    />
  );

  useEffect(() => {
    window.scroll(0, 0);

    if (tabbedContentType === "SignIn") {
      if (forgotPassword) {
        setVisibleContent(
          <ForgotPasswordPanel setForgotPassword={setForgotPassword} />
        );
      } else {
        setVisibleContent(
          <TabbedAuthentication
            setForgotPassword={setForgotPassword}
            setTabbedContentType={setTabbedContentType}
            setCreatingAccount={setCreatingAccount}
            setAccountCreated={setAccountCreated}
            setCreateAccountErrorMsg={setCreateAccountErrorMsg}
            createAccountErrorMsg={createAccountErrorMsg}
            contentType={"SignIn"}
          />
        );
      }
    } else if (tabbedContentType === "CreateAccount") {
      if (creatingAccount) {
        setVisibleContent(
          <div
            style={{
              textAlign: "center",
              display: "inline-block",
              width: "100%",
            }}
          >
            <div className="auth-creating-account-img-div">
              <img src={loadingGif} alt="" className="creating-account-img" />
            </div>
            <div className="auth-creating-account-img">
              <h2 className="auth-creating-account-img">CREATING ACCOUNT</h2>
            </div>
          </div>
        );
      } else if (accountCreated) {
        setVisibleContent(<CreateAccountSuccess />);
      } else {
        setVisibleContent(
          <TabbedAuthentication
            setForgotPassword={setForgotPassword}
            setTabbedContentType={setTabbedContentType}
            setCreatingAccount={setCreatingAccount}
            setAccountCreated={setAccountCreated}
            setCreateAccountErrorMsg={setCreateAccountErrorMsg}
            createAccountErrorMsg={createAccountErrorMsg}
            contentType={tabbedContentType}
          />
        );
      }
    }
  }, [
    creatingAccount,
    accountCreated,
    tabbedContentType,
    createAccountErrorMsg,
    forgotPassword,
  ]);

  return (
    <div className="auth-main-div" id="AuthenticationDiv">
      {visibleContent}
    </div>
  );
};

export default Authenticator;
