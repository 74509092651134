import React, { useState, useEffect } from "react";
import styles from "../styles/FileExplorer.module.css";
import FolderCollapsible from "./FolderCollapsible";
import { Amplify, API, Auth } from "aws-amplify";
import Popup from "../misc/Popup";
import loadingGif from "../../img/magInstsLoading.gif";
import awsconfig from "../../aws-exports";

const FileExplorer = () => {
  const [folders, setFolders] = useState(null);
  const [files, setFiles] = useState(null);
  const [mainContent, setMainContent] = useState(
    <Popup
      Msg={"LOADING"}
      Image={<img src={loadingGif} alt="" className="popup-img" />}
    />
  );

  const start = performance.now();

  Amplify.configure(awsconfig);

  const API_NAME = "MagInstsSoftwareRepoAPI";

  useEffect(() => {
    async function listFolders() {
      try {
        const myInit = {
          headers: {
            Authorization: (await Auth.currentSession())
              .getIdToken()
              .getJwtToken(),
          },
        };

        const resp = await API.get(API_NAME, "/ListFolders", myInit).then(
          function (res) {
            return res;
          }
        );
        setFolders(resp);
      } catch (err) {
        console.log(err);
      }
    }
    listFolders();
  }, []);

  useEffect(() => {
    async function getFileObjects() {
      if (folders) {
        return (
          <div className={styles.fileContainer}>
            {folders.map((folder, index) => {
              return <FolderCollapsible key={index} folderName={folder} />;
            })}
          </div>
        );
      }
    }

    getFileObjects().then(function (res) {
      setFiles(res);
    });
  }, [folders]);

  useEffect(() => {
    setTimeout(() => {
      setMainContent(files);
    }, 6000);
  }, [files, start]);

  return mainContent;
};

export default FileExplorer;
