import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Button } from "@mui/material";

import AccountInfo from "./AccountInfo";
import ChangeEmail from "./ChangeEmail";
import ChangePassword from "./ChangePassword";

import "../../styles/EditAccount.css";

const EditAccount = ({ user, setSignedInMainContentType }) => {

  const [editInProgress, setEditInProgress] = useState(false);


  const theme = createTheme({
    palette: {
      primary: {
        main: "#257ce1",
      },
      success: {
        main: "#009E60",
      },
    },
  });

  const handleReturnToDownloadsClick = () => {
    setSignedInMainContentType("Downloads");
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="edit-account-div" id="editAccountDiv">
        <AccountInfo
          user={user}
          editInProgress={editInProgress}
          setEditInProgress={setEditInProgress}
        />

        <ChangeEmail
          user={user}
          editInProgress={editInProgress}
          setEditInProgress={setEditInProgress}
        />

        <ChangePassword
          user={user}
          editInProgress={editInProgress}
          setEditInProgress={setEditInProgress}
        />

        <div className="return-to-downloads-div">
          <Button
            startIcon={<ArrowBackIcon />}
            className="return-to-downloads"
            sx={{
              borderRadius: "0px",
              paddingLeft: "60px",
              paddingRight: "60px",
              paddingTop: "25px",
              paddingBottom: "25px",
              margin: "0px",
              fontFamily: "FuturaBT-Book",
              fontSize: "16px",
              ":hover": { textDecoration: "underline" },
            }}
            onClick={handleReturnToDownloadsClick}
          >
            RETURN TO DOWNLOADS
          </Button>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default EditAccount;
