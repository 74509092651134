import React from "react";
import CircleCheck from "../misc/CircleCheck";
import "../styles/PasswordResetSuccess.css";

const PasswordResetSuccess = () => {
  return (
    <div className="password-reset-success-div">
      <h1 className="password-reset-success-greeting">
        Password Successfully Reset!
      </h1>
      <CircleCheck
        className="password-list-icon"
        height="128px"
        width="128px"
        fill="#009E60"
      />
    </div>
  );
};

export default PasswordResetSuccess;
