import React, { useState } from "react";
import { Auth } from "aws-amplify";
import {
  InputLabel,
  Input,
  IconButton,
  InputAdornment,
  FormControl,
  TextField,
  FormHelperText,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PasswordTooltip from "../misc/PasswordTooltip";
import ValidatePassword from "../misc/ValidatePassword";
import "../styles/ResetPassword.css";

const ResetPassword = ({ email, setPasswordReset, setPasswordResetSent, setResetErrorMsg, resetErrorMsg }) => {
  // const [errorMsg, setErrorMsg] = useState("");
  const [code, setCode] = useState(null);
  const [passwordObj, setPasswordObj] = useState({
    password: "",
    confirmPassword: "",
  });
  const [showPasswordObj, setShowPasswordObj] = useState({
    password: false,
    confirmPassword: false,
  });
  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const [pwError, setPwError] = useState(false);
  const [confirmPwError, setConfirmPwError] = useState(false);

  const [pwErrorMsg, setPwErrorMsg] = useState("")
  const [confirmPwErrorMsg, setConfirmPwErrorMsg] = useState("");
  const [showPasswordTooltip, setShowPasswordTooltip] = useState(false);

  const handleClickShowPassword = (prop) => (event) => {
    setShowPasswordObj({ ...showPasswordObj, [prop]: !showPasswordObj[prop] });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setCodeError(false);
    setPwError(false);
    setConfirmPwError(false);
    setShowPasswordTooltip(false);

    const newValue = event.target.value;
    setPasswordObj({ ...passwordObj, [prop]: newValue });

    if (prop === "password") {
      document.getElementById("passwordTooltip").style.opacity = 0;
      const { pwValid } = ValidatePassword(newValue);
      setPasswordValid(pwValid);
      setPasswordMatch(newValue === passwordObj.confirmPassword);
    }
    if (prop === "confirmPassword") {
      setPasswordMatch(newValue === passwordObj.password);
    }
  };

  const handleCodeFocus = (event) => {
    if (event.target.value.length === 0) {
      setCode("");
    }
  };

  const handleCodeBlur = (event) => {
    if (event.target.value.length === 0) {
      setCode(null);
    }
  };

  const handleCodeChange = (event) => {
    let checkPasswordSpecialChar = new RegExp("(?=.*[^A-Za-z0-9])");
    let checkPasswordUppercase = new RegExp("(?=.*[A-Z])");
    let checkPasswordLowercase = new RegExp("(?=.*[a-z])");

    if (
      (checkPasswordSpecialChar.test(event.nativeEvent.data) ||
        checkPasswordUppercase.test(event.nativeEvent.data) ||
        checkPasswordLowercase.test(event.nativeEvent.data)) &&
      event.nativeEvent.data !== null
    ) {
      if (event.target.value.length - 1 === 0) {
        setCode("");
        event.target.value = "";
      } else {
        event.target.value = code;
      }
    } else {
      setCodeError(false);
      setPwError(false);
      setConfirmPwError(false);
      document.getElementById("passwordTooltip").style.opacity = 0;
      if (event.target.value.length === 0) {
        setCode("");
        event.target.value = "";
      } else {
        setCode(event.target.value);
      }
    }
  };

  const onInputKeyDown = (event) => {
    if (event.key === "Enter") {
      document.getElementById("resetPasswordBtn").click();
    }
  };

  const handleResetPasswordBtnClick = async (event) => {
    if (
      !passwordValid ||
      !passwordMatch ||
      code.length < 6 ||
      code.length > 6
    ) {

      if (passwordObj.password.length < 1){
        setPwError(true)
        setPwErrorMsg("*Required")
      } else if (!passwordValid) {
        setPwErrorMsg("*Invalid password")
        setShowPasswordTooltip(true);
        setPwError(true);
      }

      if (passwordObj.confirmPassword.length < 1){
        setConfirmPwError(true);
        setConfirmPwErrorMsg("*Required");
      } else if (!passwordMatch) {
        setConfirmPwError(true);
        setConfirmPwErrorMsg("*Password mismatch");
      }

      if (code === null || (code.length < 6 || code.length > 6)) {
        setCodeError(true);
      }
    } else {
      try {
        document.getElementById("returnToSignIn").style.visibility = 'hidden';
        document.getElementById("returnToSignIn").style.position = 'absolute';
        document.body.className = 'wait';
        setPasswordResetSent(true);
        await Auth.forgotPasswordSubmit(email, code, passwordObj.password);
        setPasswordReset(true);
        document.body.className = '';
      } catch (err) {
        setPasswordReset(false);
        setPasswordResetSent(false);
        document.body.className = '';
        setResetErrorMsg(
          err.message
            .toUpperCase()
            .replace(".", "")
            .replace("USERNAME", "EMAIL")
        );
      }
      document.getElementById("returnToSignIn").style.visibility = 'visible';
      document.getElementById("returnToSignIn").style.position = 'relative';
    }
  };

  const textFieldLabelStyle = {
    padding: "0px 25px",
    height: "40px",
    fontSize: "20px",
    fontFamily: "FuturaBT-Book",
    boxShadow: "none",
    fontWeight: "bold",
  };

  const textFieldStyle = {
    padding: "0px 25px",
    fontSize: "18px",
    fontFamily: "FuturaBT-Book",
  };

  return (
    <>
      <h2 className="reset-code-greeting">RESET PASSWORD</h2>
      <p className="reset-code-message">
        We just sent you a verification code. <br /> Check your email and enter
        it below.
      </p>

      <TextField
        placeholder="ENTER VERIFICATION CODE"
        error={codeError}
        helperText={codeError ? "*Invalid code" : ""}
        id="codeField"
        InputLabelProps={{
          style: textFieldLabelStyle,
          shrink: code === null ? false : true,
        }}
        InputProps={{ style: textFieldStyle }}
        label="CODE"
        className="reset-code-input"
        variant="standard"
        onChange={handleCodeChange}
        onFocus={handleCodeFocus}
        onBlur={handleCodeBlur}
        onKeyDown={onInputKeyDown}
      />

      <FormControl
        error={pwError}
        sx={{
          m: 1,
          width: "calc(100% - 100px)",
          marginLeft: "50px",
          marginRight: "50px",
          padding: "0px 10px",
          height: "40px",
          marginTop: "35px",
        }}
        variant="standard"
      >
        <InputLabel htmlFor="passwordField" style={textFieldLabelStyle}>
          PASSWORD
        </InputLabel>
        <Input
          placeholder="ENTER NEW PASSWORD"
          style={textFieldStyle}
          id="passwordField"
          type={showPasswordObj.password ? "text" : "password"}
          onChange={handlePasswordChange("password")}
          onKeyDown={onInputKeyDown}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword("password")}
                onMouseDown={handleMouseDownPassword}
              >
                {!showPasswordObj.password ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText
          id="component-error-text"
          htmlFor="confirmPasswordField"
        >
          {pwError ? pwErrorMsg : ""}
        </FormHelperText>
      </FormControl>

      <PasswordTooltip password={passwordObj.password} opacity={showPasswordTooltip ? 100:0}/>

      <FormControl
        error={confirmPwError}
        sx={{
          m: 1,
          width: "calc(100% - 100px)",
          marginLeft: "50px",
          marginRight: "50px",
          padding: "0px 10px",
          height: "40px",
          marginTop: "35px",
        }}
        variant="standard"
      >
        <InputLabel htmlFor="confirmPasswordField" style={textFieldLabelStyle}>
          CONFIRM PASSWORD
        </InputLabel>
        <Input
          placeholder="CONFIRM YOUR NEW PASSWORD"
          style={textFieldStyle}
          id="confirmPasswordField"
          type={showPasswordObj.confirmPassword ? "text" : "password"}
          onChange={handlePasswordChange("confirmPassword")}
          onKeyDown={onInputKeyDown}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword("confirmPassword")}
                onMouseDown={handleMouseDownPassword}
              >
                {!showPasswordObj.confirmPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText
          id="component-error-text"
          htmlFor="confirmPasswordField"
        >
          {confirmPwError ? confirmPwErrorMsg : ""}
        </FormHelperText>
      </FormControl>

      <div className="verify-code-error-msg-div">
        <h4 className="verify-code-error-msg" id="errorMsg">
          {resetErrorMsg}
        </h4>
      </div>
      <button
        id="resetPasswordBtn"
        className="reset-password-btn"
        onClick={handleResetPasswordBtnClick}
      >
        RESET PASSWORD
      </button>
    </>
  );
};

export default ResetPassword;
