import { useState, useEffect } from "react";
import ResetPassword from "./ResetPassword";
import PasswordResetSuccess from "./PasswordResetSuccess";
import loadingGif from "../../img/magInstsLoading.gif";
import "../styles/ResetPasswordPanel.css";

const ResetPasswordPanel = ({ email }) => {
  const [passwordReset, setPasswordReset] = useState(false);
  const [passwordResetSent, setPasswordResetSent] = useState(false);
  const [resetErrorMsg, setResetErrorMsg] = useState("")
  const [mainContent, setMainContent] = useState(
    <ResetPassword email={email} setPasswordReset={setPasswordReset} />
  );

  useEffect(() => {
    setMainContent(
      passwordResetSent ? (
        passwordReset ? (
          <PasswordResetSuccess />
        ) : (
          <div
            style={{
              textAlign: "center",
              display: "inline-block",
              width: "100%",
            }}
          >
            <div className="reset-pw-loading-img-div">
              <img src={loadingGif} alt="" className="reset-pw-loading-img" />
            </div>
            <div className="reset-pw-loading-msg-div">
              <h2 className="reset-pw-loading-msg">RESETTING PASSWORD</h2>
            </div>
          </div>
        )
      ) : (
        <ResetPassword
          email={email}
          setPasswordReset={setPasswordReset}
          setPasswordResetSent={setPasswordResetSent}
          setResetErrorMsg={setResetErrorMsg}
          resetErrorMsg={resetErrorMsg}
        />
      )
    );
  }, [passwordReset, passwordResetSent, email]);

  return mainContent;
};

export default ResetPasswordPanel;
