import React, { useState, useEffect } from "react";
import Accordion from "../misc/Accordion";
import { Amplify, API, Auth } from "aws-amplify";
import awsconfig from "../../aws-exports";
import "../styles/FolderCollapsible.css";

const FolderCollapsible = ({ folderName }) => {
  const [files, setFiles] = useState(null);

  Amplify.configure(awsconfig);

  const API_NAME = "MagInstsSoftwareRepoAPI";

  useEffect(() => {
    async function listFiles() {
      try {
        const myInit = {
          headers: {
            Authorization: (await Auth.currentSession())
              .getIdToken()
              .getJwtToken(),
          },
        };

        const repoFiles = await API.get(API_NAME, `/ListFiles/${folderName}/`, myInit).then(function (res) {
          return res;
        });
        setFiles(repoFiles);
      } catch (err) {
        console.log(err);
      }
    }
    listFiles();
  }, [folderName]);

  const FileClicked = async (e, fileRelPath) => {
    // Prevents the page from redirecting
    e.preventDefault();
    try {
    const myInit = {
      headers: {
        Authorization: (await Auth.currentSession())
          .getIdToken()
          .getJwtToken(),
      },
    };

    const signedURL = await API.get(
      API_NAME,
      `/GetPresignedURL/${fileRelPath.replaceAll("/", "|")}`,
      myInit
    );

    // Create link, download, destroy link
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.href = signedURL;
    a.click(); // Downloads file
    a.parentNode.removeChild(a);
    } catch (err) {
      console.log(err)
    }
  };

  if (files) {
    let toRet;
    if (files.length === 0) {
      toRet = (
        <Accordion title={folderName.replaceAll("_", " ")}>
          <h3 className="no-software">Software Coming Soon!</h3>
        </Accordion>
      );
    } else {
      if (files.length === 1) {
        toRet = (
          <Accordion title={folderName.replaceAll("_", " ")}>
            <h3
              className="fileLabel"
              onClick={(e) => {
                FileClicked(e, files[0].filepath);
              }}
            >
              {files[0].filename}
            </h3>
          </Accordion>
        );
      } else {
        toRet = (
          <Accordion title={folderName.replaceAll("_", " ")}>
            {files.map((file, index) => (
              <h3
                key={index}
                className="fileLabel"
                onClick={(e) => {
                  FileClicked(e, file.filepath);
                }}
              >
                {file.filename}
              </h3>
            ))}
          </Accordion>
        );
      }
    }

    return toRet;
  }

  //   return repoFiles;
};
export default FolderCollapsible;
