function ValidateEmail(email) {
  const isMatch = email.match(
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  );

  if (isMatch) {
    return true;
  } else {
    return false;
  }
}

export default ValidateEmail;
