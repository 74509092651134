import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import EditIcon from "@mui/icons-material/Edit";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Button, IconButton, TextField, FormControl } from "@mui/material";

const AccountInfo = ({ user, setEditInProgress, editInProgress }) => {
  //   ------------ State Initialization Stuff ------------
  const [accountInfoEditable, setAccountInfoEditable] = useState(false);

  const [accountInfoFieldValues, setAccountInfoFieldValues] = useState({
    firstName: user.attributes["custom:first_name"],
    lastName: user.attributes["custom:last_name"],
    organization: user.attributes["custom:organization"],
  });

  const [accountInfoErrors, setAccountInfoErrors] = useState({
    firstName: false,
    lastName: false,
    organization: false,
  });

  const [infoChangeSubmitErrorMsg, setInfoChangeSubmitErrorMsg] = useState("");
  const [infoChangeSuccessMsg, setInfoChangeSuccessMsg] = useState("");

  //   ------------ Style Stuff ------------
  const theme = createTheme({
    palette: {
      primary: {
        main: "#257ce1",
      },
      success: {
        main: "#009E60",
      },
    },
  });

  const textFieldLabelStyle = {
    padding: "0px 25px",
    height: "40px",
    fontSize: "20px",
    fontFamily: "FuturaBT-Book",
    boxShadow: "none",
    fontWeight: "bold",
    pointerEvents: accountInfoEditable ? "auto" : "none",
  };

  const textFieldStyle = {
    padding: "0px 25px",
    fontSize: "18px",
    fontFamily: "FuturaBT-Book",
    pointerEvents: accountInfoEditable ? "auto" : "none",
  };

  //   ------------ Callback Stuff ------------
  const handleEditInfoIconClicked = () => {
    setEditInProgress(true);
    setAccountInfoEditable(true);
    document.getElementById("firstNameField").focus();
  };

  const handleCancelEditAccountInfoClick = () => {
    setAccountInfoEditable(false);
    setEditInProgress(false);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, "100");
    setTimeout(() => {
      setAccountInfoErrors({
        firstName: false,
        lastName: false,
        organization: false,
      });
      setAccountInfoFieldValues({
        firstName: user.attributes["custom:first_name"],
        lastName: user.attributes["custom:last_name"],
        organization: user.attributes["custom:organization"],
      });
    }, "400");
  };

  const handleInputChange = (prop) => (event) => {
    setAccountInfoFieldValues({
      ...accountInfoFieldValues,
      [prop]: event.target.value,
    });

    setAccountInfoErrors({
      firstName: false,
      lastName: false,
      organization: false,
    });
  };

  const handleSaveAccountChangesClick = async () => {
    let errors = {
      firstName: false,
      lastName: false,
      organization: false,
    };

    let crossCheckPassed = true;

    if (accountInfoFieldValues.firstName.length < 1) {
      errors.firstName = true;
      crossCheckPassed = false;
    }

    if (accountInfoFieldValues.lastName.length < 1) {
      errors.lastName = true;
      crossCheckPassed = false;
    }

    if (accountInfoFieldValues.organization.length < 1) {
      errors.organization = true;
      crossCheckPassed = false;
    }
    setAccountInfoErrors(errors);

    if (crossCheckPassed) {
      try {
        await Auth.updateUserAttributes(user, {
          "custom:first_name": accountInfoFieldValues.firstName,
          "custom:last_name": accountInfoFieldValues.lastName,
          "custom:organization": accountInfoFieldValues.organization,
        });

        setAccountInfoEditable(false);
        setEditInProgress(false);
        setInfoChangeSuccessMsg("CHANGE SUCCESSFUL!");
      } catch (err) {
        setInfoChangeSubmitErrorMsg(
          err.message
            .toUpperCase()
            .replace(".", "")
            .replace("USERNAME", "EMAIL")
        );
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (editInProgress) {
        setInfoChangeSuccessMsg("");
    }
  }, [editInProgress])

  return (
    <ThemeProvider theme={theme}>
      <div
        className="account-info-div"
        id="accountInfoDiv"
        style={{ maxHeight: accountInfoEditable ? "530px" : "375px" }}
      >
        <div className="account-info-header-div">
          <h2 className="account-info-header">ACCOUNT INFO</h2>
          <IconButton
            sx={{
              marginBottom: "25px",
              marginTop: "50px",
              padding: "0px",
              marginLeft: "10px",
              width: "32px",
              transition: "opacity 0.3s linear",
            }}
            className={
              editInProgress ? "edit-icon-button disabled" : "edit-icon-button"
            }
            size="large"
            onClick={handleEditInfoIconClicked}
            tabindex={editInProgress ? "-1" : "0"}
          >
            <EditIcon className="edit-icon" />
          </IconButton>
          <span className="info-change-success">{infoChangeSuccessMsg}</span>
        </div>

        <FormControl
          sx={{
            m: 1,
            width: "calc(100% - 100px)",
            marginLeft: "50px",
            marginRight: "50px",
            padding: "0px 10px",
            height: "40px",
            marginTop: "0px",
            readOnly: !accountInfoEditable,
          }}
          variant="standard"
        >
          <TextField
            value={accountInfoFieldValues.firstName}
            error={accountInfoErrors.firstName}
            helperText={accountInfoErrors.firstName ? "*Required" : ""}
            placeholder="ENTER YOUR FIRST NAME"
            id="firstNameField"
            InputLabelProps={{
              style: textFieldLabelStyle,
            }}
            InputProps={{
              style: textFieldStyle,
            }}
            inputProps={{ tabindex: accountInfoEditable ? "0" : "-1" }}
            label="FIRST NAME"
            variant="standard"
            onChange={handleInputChange("firstName")}
            //   onKeyDown={onInputKeyDown}
          />
        </FormControl>

        <FormControl
          sx={{
            m: 1,
            width: "calc(100% - 100px)",
            marginLeft: "50px",
            marginRight: "50px",
            padding: "0px 10px",
            height: "40px",
            marginTop: "35px",
          }}
          variant="standard"
        >
          <TextField
            value={accountInfoFieldValues.lastName}
            error={accountInfoErrors.lastName}
            helperText={accountInfoErrors.lastName ? "*Required" : ""}
            placeholder="ENTER YOUR LAST NAME"
            id="lastNameField"
            InputLabelProps={{
              style: textFieldLabelStyle,
            }}
            InputProps={{
              style: textFieldStyle,
              readOnly: !accountInfoEditable,
            }}
            inputProps={{ tabindex: accountInfoEditable ? "0" : "-1" }}
            label="LAST NAME"
            variant="standard"
            onChange={handleInputChange("lastName")}
            //   onKeyDown={onInputKeyDown}
          />
        </FormControl>

        <FormControl
          sx={{
            m: 1,
            width: "calc(100% - 100px)",
            marginLeft: "50px",
            marginRight: "50px",
            padding: "0px 10px",
            height: "40px",
            marginTop: "35px",
            marginBottom: "15px",
          }}
          variant="standard"
        >
          <TextField
            value={accountInfoFieldValues.organization}
            error={accountInfoErrors.organization}
            helperText={accountInfoErrors.organization ? "*Required" : ""}
            placeholder="ENTER YOUR ORGANIZATION"
            id="organizationField"
            InputLabelProps={{
              style: textFieldLabelStyle,
            }}
            InputProps={{
              style: textFieldStyle,
              readOnly: !accountInfoEditable,
            }}
            inputProps={{ tabindex: accountInfoEditable ? "0" : "-1" }}
            label="ORGANIZATION"
            variant="standard"
            onChange={handleInputChange("organization")}
            //   onKeyDown={onInputKeyDown}
          />
        </FormControl>

        <div className="change-info-error-msg-div">
          <h4 className="change-info-error-msg" id="errorMsg">
            {infoChangeSubmitErrorMsg}
          </h4>
        </div>

        <button
          tabindex={accountInfoEditable ? "0" : "-1"}
          id="saveAccountInfoBtn"
          className={
            accountInfoEditable
              ? "save-account-info-btn"
              : "save-account-info-btn disabled"
          }
          onClick={handleSaveAccountChangesClick}
        >
          SAVE CHANGES
        </button>

        <Button
          tabindex={accountInfoEditable ? "0" : "-1"}
          className={
            accountInfoEditable ? "cancel-changes" : "cancel-changes disabled"
          }
          sx={{
            borderRadius: "0px",
            paddingLeft: "auto",
            paddingRight: "auto",
            left: "50%",
            transform: "translateX(-50%)",
            paddingTop: "25px",
            paddingBottom: "35px",
            margin: "0px",
            fontFamily: "FuturaBT-Book",
            fontSize: "16px",
            ":hover": { textDecoration: "underline" },
          }}
          onClick={handleCancelEditAccountInfoClick}
        >
          DISCARD CHANGES
        </Button>
      </div>
    </ThemeProvider>
  );
};

export default AccountInfo;
