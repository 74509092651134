import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { TextField } from "@mui/material";
import ValidateEmail from "../misc/ValidateEmail";
import "../styles/SendForgotPasswordCode.css";

const SendForgotPasswordCode = ({ setValues, values }) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const handleEmailChange = (event) => {
    setEmailError(false);
    setErrorMsg("");
    setEmailValid(ValidateEmail(event.target.value));
    setValues({ ...values, email: event.target.value });
  };

  const onInputKeyDown = (event) => {
    if (event.key === "Enter") {
      document.getElementById("sendCodeBtn").click();
    }
  };

  async function sendResetCode(email) {
    setEmailError(false);
    setErrorMsg("");
    if (!emailValid) {
      setEmailError(true);
    } else {
      try {
        setErrorMsg("");
        await Auth.forgotPassword(email);
        setValues({ ...values, codeSent: true });
      } catch (err) {
        setErrorMsg(
          err.message
            .toUpperCase()
            .replace(".", "")
            .replace("USERNAME", "EMAIL")
        );
        setValues({ ...values, codeSent: false });
      }
    }
  }

  const handleSendCodeClicked = (event) => {
    sendResetCode(values.email);
  };

  const textFieldLabelStyle = {
    padding: "0px 25px",
    height: "40px",
    fontSize: "20px",
    fontFamily: "FuturaBT-Book",
    boxShadow: "none",
    fontWeight: "bold",
  };

  const textFieldStyle = {
    padding: "0px 25px",
    fontSize: "18px",
    fontFamily: "FuturaBT-Book",
  };

  return (
    <>
      <h2 className="forgot-password-greeting">FORGOT YOUR PASSWORD?</h2>
      <p className="forgot-password-message">
        No worries, enter your email below and we'll send you a code to reset
        your password.
      </p>
      <TextField
        placeholder="ENTER YOUR EMAIL"
        error={emailError}
        helperText={emailError ? "Invalid email" : ""}
        id="emailField"
        InputLabelProps={{
          style: textFieldLabelStyle,
        }}
        InputProps={{ style: textFieldStyle }}
        label="EMAIL"
        className="forgot-password-email-input"
        variant="standard"
        onChange={handleEmailChange}
        onKeyDown={onInputKeyDown}
      />

      <div className="send-code-error-msg-div">
        <h4 className="send-code-error-msg" id="errorMsg">
          {errorMsg}
        </h4>
      </div>
      <button id="sendCodeBtn" className="send-code-btn" onClick={handleSendCodeClicked}>
        SEND CODE
      </button>
    </>
  );
};

export default SendForgotPasswordCode;
