import React, { useState, useEffect } from "react"
import FileExplorer from "./FileExplorer"
import EditAccount from "./EditAccount/EditAccount"

const SignedInContent = ({ setSignedInMainContentType, signedInMainContentType, user }) => {
    const [mainContent, setMainContent] = useState(<FileExplorer />)

    useEffect(() => {

        if (signedInMainContentType === "Downloads") {
            setMainContent(<FileExplorer />)
        } else if (signedInMainContentType === "EditAccount") {
            setMainContent(<EditAccount user={user} setSignedInMainContentType={setSignedInMainContentType}/>)
        }
        
    }, [signedInMainContentType, user, setSignedInMainContentType])

    return mainContent
}

export default SignedInContent