import React, { useState, useRef } from "react";
import Chevron from './Chevron'

import "../styles/Accordion.css";

function Accordion(props) {

  const [isActive, setIsActive] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [rotateState, setRotateState] = useState("accordion__icon")
  const [isHover, setIsHover] = useState(false);
  const content = useRef(null);
  
  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  function toggleAccordian () {
    setIsActive( isActive === "" ? "active":"")
    setHeightState( isActive === "active" ? "0px":`${content.current.scrollHeight}px`)
    setRotateState( isActive === "active" ? "accordion__icon" : "accordion__icon rotate")
  }

  return (
    <div className="accordion__section">
      <button className={`accordion ${isActive}`} onClick={toggleAccordian} onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <h3 className="accordion__title" style={{color: (isHover || isActive) ? "white":"black"}}>{props.title}</h3>
        <Chevron className={rotateState} width={10} style={{fill: (isHover || isActive) ? "white":"black", transition: "fill 0.4s ease"}} />
      </button>
      <div ref={content} style={{maxHeight: `${setHeight}`}} className="accordion__content">
        {props.children}
      </div>
    </div>
  );
}

export default Accordion