import "../styles/CreateAccount.css";
import React, { useState } from "react";
import {
  InputLabel,
  Input,
  IconButton,
  InputAdornment,
  TextField,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PasswordTooltip from "../misc/PasswordTooltip";
import ValidatePassword from "../misc/ValidatePassword";
import ValidateEmail from "../misc/ValidateEmail";
import { Auth } from "aws-amplify";

const CreateAccount = ({
  setCreatingAccount,
  setAccountCreated,
  setCreateAccountErrorMsg,
  createAccountErrorMsg,
}) => {
  // if (document.getElementById("AuthenticationDiv") !== null) {
  //   document.getElementById("AuthenticationDiv").style.height = "765px";
  // }

  const [showPasswordObj, setShowPasswordObj] = useState({
    password: false,
    confirmPassword: false,
  });

  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    organization: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [orgError, setOrgError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [pwErrorMsg, setPwErrorMsg] = useState("");
  const [confirmPwErrorMsg, setConfirmPwErrorMsg] = useState("");

  const [showPasswordTooltip, setShowPasswordTooltip] = useState(false);
  // const [errorMsg, setErrorMsg] = useState("");

  const theme = createTheme({
    palette: {
      primary: {
        main: "#257ce1",
      },
      success: {
        main: "#009E60",
      },
    },
  });

  const textFieldLabelStyle = {
    padding: "0px 25px",
    height: "40px",
    fontSize: "20px",
    fontFamily: "FuturaBT-Book",
    boxShadow: "none",
    fontWeight: "bold",
  };

  const textFieldStyle = {
    padding: "0px 25px",
    fontSize: "18px",
    fontFamily: "FuturaBT-Book",
  };

  const handleInputChange = (prop) => (event) => {
    setFirstNameError(false);
    setLastNameError(false);
    setOrgError(false);
    setEmailError(false);
    setEmailErrorMsg("");
    setPasswordError(false);
    setConfirmPasswordError(false);
    setShowPasswordTooltip(false);
    setCreateAccountErrorMsg("");

    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = (prop) => (event) => {
    setShowPasswordObj({ ...showPasswordObj, [prop]: !showPasswordObj[prop] });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onInputKeyDown = (event) => {
    if (event.key === "Enter") {
      document.getElementById("createAccountBtn").click();
    }
  };

  const handleCreateAccountClicked = async () => {
    let clearToSubmit = true;

    if (values.firstName.length < 1) {
      setFirstNameError(true);
      clearToSubmit = false;
    }

    if (values.lastName.length < 1) {
      setLastNameError(true);
      clearToSubmit = false;
    }

    if (values.organization.length < 1) {
      setOrgError(true);
      clearToSubmit = false;
    }

    if (values.email.length < 1) {
      setEmailError(true);
      setEmailErrorMsg("*Required");
      clearToSubmit = false;
    } else if (!ValidateEmail(values.email)) {
      setEmailError(true);
      setEmailErrorMsg("*Invalid email");
      clearToSubmit = false;
    }

    const { pwValid } = ValidatePassword(values.password);
    if (values.password.length < 1) {
      setPasswordError(true);
      setPwErrorMsg("*Required");
      clearToSubmit = false;
    } else if (!pwValid) {
      setPasswordError(true);
      setShowPasswordTooltip(true);
      setPwErrorMsg("*Invalid password");
      clearToSubmit = false;
    }

    if (values.confirmPassword.length < 1) {
      setConfirmPasswordError(true);
      setConfirmPwErrorMsg("*Required");
      clearToSubmit = false;
    }
    if (values.password !== values.confirmPassword) {
      setConfirmPasswordError(true);
      setConfirmPwErrorMsg("*Password mismatch");
      clearToSubmit = false;
    }

    if (clearToSubmit) {
      try {
        setCreatingAccount(true);

        await Auth.signUp({
          username: values.email,
          password: values.password,
          attributes: {
            "custom:first_name": values.firstName,
            "custom:last_name": values.lastName,
            "custom:organization": values.organization,
          },
        });

        setCreatingAccount(false);
        setAccountCreated(true);
      } catch (error) {
        setCreatingAccount(false);
        setAccountCreated(false);
        setCreateAccountErrorMsg(
          error.message
            .toUpperCase()
            .replace(".", "")
            .replace("USERNAME", "EMAIL")
        );
        console.log("error signing up:", error);
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <h2 className="greeting">CREATE AN ACCOUNT</h2>

      <FormControl
        sx={{
          m: 1,
          width: "calc(100% - 100px)",
          marginLeft: "50px",
          marginRight: "50px",
          padding: "0px 10px",
          height: "40px",
          marginTop: "0px",
        }}
        variant="standard"
      >
        <TextField
          error={firstNameError}
          helperText={firstNameError ? "*Required" : ""}
          placeholder="ENTER YOUR FIRST NAME"
          id="firstNameField"
          InputLabelProps={{
            style: textFieldLabelStyle,
          }}
          InputProps={{ style: textFieldStyle }}
          label="FIRST NAME"
          variant="standard"
          onChange={handleInputChange("firstName")}
          onKeyDown={onInputKeyDown}
        />
      </FormControl>

      <FormControl
        sx={{
          m: 1,
          width: "calc(100% - 100px)",
          marginLeft: "50px",
          marginRight: "50px",
          padding: "0px 10px",
          height: "40px",
          marginTop: "35px",
        }}
        variant="standard"
      >
        <TextField
          error={lastNameError}
          helperText={lastNameError ? "*Required" : ""}
          placeholder="ENTER YOUR LAST NAME"
          id="lastNameField"
          InputLabelProps={{
            style: textFieldLabelStyle,
          }}
          InputProps={{ style: textFieldStyle }}
          label="LAST NAME"
          variant="standard"
          onChange={handleInputChange("lastName")}
          onKeyDown={onInputKeyDown}
        />
      </FormControl>

      <FormControl
        sx={{
          m: 1,
          width: "calc(100% - 100px)",
          marginLeft: "50px",
          marginRight: "50px",
          padding: "0px 10px",
          height: "40px",
          marginTop: "35px",
        }}
        variant="standard"
      >
        <TextField
          error={orgError}
          helperText={orgError ? "*Required" : ""}
          placeholder="ENTER YOUR ORGANIZATION"
          id="organizationField"
          InputLabelProps={{
            style: textFieldLabelStyle,
          }}
          InputProps={{ style: textFieldStyle }}
          label="ORGANIZATION"
          variant="standard"
          onChange={handleInputChange("organization")}
          onKeyDown={onInputKeyDown}
        />
      </FormControl>

      <FormControl
        sx={{
          m: 1,
          width: "calc(100% - 100px)",
          marginLeft: "50px",
          marginRight: "50px",
          padding: "0px 10px",
          height: "40px",
          marginTop: "35px",
        }}
        variant="standard"
      >
        <TextField
          error={emailError}
          helperText={emailErrorMsg}
          placeholder="ENTER YOUR EMAIL"
          id="emailField"
          InputLabelProps={{
            style: textFieldLabelStyle,
          }}
          InputProps={{ style: textFieldStyle }}
          label="EMAIL"
          variant="standard"
          onChange={handleInputChange("email")}
          onKeyDown={onInputKeyDown}
        />
      </FormControl>

      <FormControl
        error={passwordError}
        sx={{
          m: 1,
          width: "calc(100% - 100px)",
          marginLeft: "50px",
          marginRight: "50px",
          padding: "0px 10px",
          height: "40px",
          marginTop: "40px",
        }}
        variant="standard"
      >
        <InputLabel
          htmlFor="createAcctPasswordField"
          style={textFieldLabelStyle}
        >
          PASSWORD
        </InputLabel>
        <Input
          placeholder="ENTER YOUR PASSWORD"
          style={textFieldStyle}
          id="createAcctPasswordField"
          type={showPasswordObj.password ? "text" : "password"}
          onChange={handleInputChange("password")}
          onKeyDown={onInputKeyDown}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword("password")}
                onMouseDown={handleMouseDownPassword}
              >
                {!showPasswordObj.password ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText
          id="component-error-text"
          htmlFor="createAcctPasswordField"
        >
          {passwordError ? pwErrorMsg : ""}
        </FormHelperText>
      </FormControl>

      <PasswordTooltip
        password={values.password}
        opacity={showPasswordTooltip ? 100 : 0}
      />

      <FormControl
        error={confirmPasswordError}
        sx={{
          m: 1,
          width: "calc(100% - 100px)",
          marginLeft: "50px",
          marginRight: "50px",
          padding: "0px 10px",
          height: "40px",
          marginTop: "35px",
        }}
        variant="standard"
      >
        <InputLabel
          htmlFor="createAcctConfirmPasswordField"
          style={textFieldLabelStyle}
        >
          CONFIRM PASSWORD
        </InputLabel>
        <Input
          placeholder="CONFIRM YOUR PASSWORD"
          style={textFieldStyle}
          id="createAcctConfirmPasswordField"
          type={showPasswordObj.confirmPassword ? "text" : "password"}
          onChange={handleInputChange("confirmPassword")}
          onKeyDown={onInputKeyDown}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword("confirmPassword")}
                onMouseDown={handleMouseDownPassword}
              >
                {!showPasswordObj.confirmPassword ? (
                  <Visibility />
                ) : (
                  <VisibilityOff />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText
          id="component-error-text"
          htmlFor="createAcctConfirmPasswordField"
        >
          {confirmPasswordError ? confirmPwErrorMsg : ""}
        </FormHelperText>
      </FormControl>

      <div className="create-account-error-msg-div">
        <h4 className="create-account-error-msg" id="errorMsg">
          {createAccountErrorMsg}
        </h4>
      </div>

      <button
        id="createAccountBtn"
        className="create-account-btn"
        onClick={handleCreateAccountClicked}
      >
        CREATE ACCOUNT
      </button>
    </ThemeProvider>
  );
};

export default CreateAccount;
