import React from "react";
import CircleCheck from "../misc/CircleCheck";
import "../styles/CreateAccountSuccess.css";

const CreateAccountSuccess = () => {
//   document.getElementById("AuthenticationDiv").style.height = "auto";

  return (
    <div className="create-account-success-div">
      <h1 className="create-account-success-greeting">ACCOUNT CREATED!</h1>
      <CircleCheck height="128px" width="128px" fill="#009E60" />
      <p className="create-account-success-msg">
        Your account has been created. New accounts are subject to review. Upon approval, you will recieve an
        email notifying you that your account is verified.
      </p>
      <a href="https://magnitudeinstruments.com/" style={{textDecoration: "none"}}>
        <h3 className="return-home">RETURN HOME</h3>
      </a>
    </div>
  );
};

export default CreateAccountSuccess;
