import React, { useState, useEffect } from "react";
import CircleCheck from "./CircleCheck";
import CircleXMark from "./CircleXMark";
import ValidatePassword from "./ValidatePassword";
import "../styles/PasswordTooltip.css"

const PasswordTooltip = ({ password, opacity }) => {
  const [passwordVerified, setPasswordVerified] = useState({
    pwLength: false,
    pwUpper: false,
    pwLower: false,
    pwNumber: false,
    pwSpecial: false,
    pwValid: false,
  })

  const checkMarkIcon = (
    <CircleCheck
      className="password-list-icon"
      height="16px"
      width="16px"
      fill="#009E60"
    />
  );
  const xMarkIcon = (
    <CircleXMark
      className="password-list-icon"
      height="16px"
      width="16px"
      fill="#D22B2B"
    />
  );

  useEffect(() => {
    setPasswordVerified(ValidatePassword(password))
  }, [password])
  

  return (
    <div className="password-tooltip" id="passwordTooltip" style={{opacity: opacity}}>
      <label className="password-label">Password must have:</label>
      <ul className="password-list">
        <li className="password-list-item">
          {passwordVerified.pwLength ? checkMarkIcon : xMarkIcon}
          <label>At least 8 characters</label>
        </li>
        <li className="password-list-item">
          {passwordVerified.pwLower ? checkMarkIcon : xMarkIcon}
          <label>A lower case letter (a-z)</label>
        </li>
        <li className="password-list-item">
          {passwordVerified.pwUpper ? checkMarkIcon : xMarkIcon}
          <label>An upper case letter (A-Z)</label>
        </li>
        <li className="password-list-item">
          {passwordVerified.pwNumber ? checkMarkIcon : xMarkIcon}
          <label>A number (0-9)</label>
        </li>
        <li className="password-list-item">
          {passwordVerified.pwSpecial ? checkMarkIcon : xMarkIcon}
          <label>A special character</label>
        </li>
      </ul>
    </div>
  );
};

export default PasswordTooltip