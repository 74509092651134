import React from "react";

import "../styles/Popup.css";

const Popup = ({ Msg, Image }) => {
  return (
    <div className="popup-div">
      <div className="popup-img-div">{Image}</div>
      <div className="popup-msg-div">
        <h2 className="popup-msg">{Msg}</h2>
      </div>
    </div>
  );
};

export default Popup;
