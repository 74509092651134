import "../styles/TabbedAuthentication.css";
import React, { useEffect, useState } from "react";
import SignIn from "./SignIn";
import CreateAccount from "./CreateAccount";

const TabbedAuthentication = ({
  setForgotPassword,
  setTabbedContentType,
  setCreatingAccount,
  setAccountCreated,
  setCreateAccountErrorMsg,
  createAccountErrorMsg,
  contentType,
}) => {
  window.scrollTo(0, 0);

  const [visibleContentType, setVisibleContentType] = useState(contentType);
  const [visibleContent, setVisibleContent] = useState(
    <SignIn setForgotPassword={setForgotPassword} />
  );

  // setTabbedContentType("SignIn");

  const createAccountClicked = () => {
    // window.scrollTo(0, 0);
    setVisibleContentType("CreateAccount");
    setTabbedContentType("CreateAccount");
    // setVisibleContent(
    //   <CreateAccount setCreatingAccount={setCreatingAccount} setAccountCreated={setAccountCreated} />
    // );
  };

  const signInClicked = () => {
    setTabbedContentType("SignIn");
    setVisibleContentType("SignIn");
    // setVisibleContent(<SignIn forgotPasswordClicked={forgotPasswordClicked} />);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (visibleContentType === "SignIn") {
      setVisibleContent(<SignIn setForgotPassword={setForgotPassword} />);
    } else if (visibleContentType === "CreateAccount") {
      setVisibleContent(
        <CreateAccount
          setCreatingAccount={setCreatingAccount}
          setAccountCreated={setAccountCreated}
          setCreateAccountErrorMsg={setCreateAccountErrorMsg}
          createAccountErrorMsg={createAccountErrorMsg}
        />
      );
    }
  }, [
    visibleContentType,
    setForgotPassword,
    setAccountCreated,
    setCreatingAccount,
    createAccountErrorMsg,
    setCreateAccountErrorMsg
  ]);

  return (
    <div>
      <div className="tab" id="tabs">
        <button
          className={
            visibleContentType === "SignIn" ? "tab-button active" : "tab-button"
          }
          onClick={signInClicked}
        >
          SIGN IN
        </button>
        <button
          className={
            visibleContentType === "CreateAccount"
              ? "tab-button active"
              : "tab-button"
          }
          onClick={createAccountClicked}
        >
          CREATE ACCOUNT
        </button>
      </div>
      <div id="signInCreateAccountDiv">{visibleContent}</div>
    </div>
  );
};

export default TabbedAuthentication;
