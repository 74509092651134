import "../styles/ForgotPasswordPanel.css";
import React, { useState, useEffect } from "react";

import SendForgotPasswordCode from "./SendForgotPasswordCode";
import ResetPasswordPanel from "./ResetPasswordPanel";

const ForgotPassword = ({ setForgotPassword }) => {
  const [values, setValues] = useState({
    email: "",
    codeSent: false,
    password: "",
    confirmPassword: "",
    showPassword: false,
    showConfirmPassword: false,
    passwordValid: false,
  });

  const [mainContent, setMainContent] = useState();

  useEffect(() => {
    if (values.codeSent) {
      setMainContent(<ResetPasswordPanel email={values.email} />);
    } else {
      setMainContent(
        <SendForgotPasswordCode setValues={setValues} values={values} />
      );
    }
  }, [values, setValues]);

  const returnToSignInClicked = () => {
    setForgotPassword(false);
  }

  return (
    <>
      {mainContent}
      <div
        style={{ textAlign: "center", display: "inline-block", width: "100%" }}
      >
        <h4
          id="returnToSignIn"
          className="return-to-sign-in"
          onClick={returnToSignInClicked}
        >
          RETURN TO SIGN IN
        </h4>
      </div>
    </>
  );
};

export default ForgotPassword;
