import "../styles/SignIn.css";
import React, { useState } from "react";
import {
  InputLabel,
  Input,
  IconButton,
  InputAdornment,
  FormControl,
  TextField,
  FormHelperText,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Auth } from "aws-amplify";
import ValidateEmail from "../misc/ValidateEmail";
import ValidatePassword from "../misc/ValidatePassword";

const SignIn = ({ setForgotPassword }) => {
  // if (document.getElementById("AuthenticationDiv") !== null){
  //   document.getElementById("AuthenticationDiv").style.height = "465px"
  // }

  const [values, setValues] = useState({
    email: "",
    password: "",
    showPassword: false,
  });

  const [errorMsg, setErrorMsg] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#257ce1",
      },
    },
  });

  const onInputKeyDown = (event) => {
    if (event.key === "Enter") {
      document.getElementById("signInBtn").click();
    }
  };

  const handleInputChange = (prop) => (event) => {
    setErrorMsg("");
    setEmailError(false);
    setPasswordError(false);
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const handleSignInClicked = async (event) => {
    setErrorMsg("");
    setEmailError(false);
    setPasswordError(false);
    const { pwValid } = ValidatePassword(values.password);
    if (ValidateEmail(values.email) && pwValid) {
      try {
        await Auth.signIn(values.email, values.password);
      } catch (err) {
        setErrorMsg(err.message.toUpperCase().replace(".", ""));
        console.log("error signing in", err);
      }
    } else {
      if (!ValidateEmail(values.email)) {
        setEmailError(true);
      }

      if (!pwValid) {
        setPasswordError(true);
      }
    }
  };

  const forgotPasswordClicked = () => {
    setForgotPassword(true);
  }

  const textFieldLabelStyle = {
    padding: "0px 25px",
    height: "40px",
    fontSize: "20px",
    fontFamily: "FuturaBT-Book",
    boxShadow: "none",
    fontWeight: "bold",
  };

  const textFieldStyle = {
    padding: "0px 25px",
    fontSize: "18px",
    fontFamily: "FuturaBT-Book",
  };

  return (
    <ThemeProvider theme={theme}>
      <h2 className="sign-in-greeting">SIGN IN TO YOUR ACCOUNT</h2>

      <TextField
        error={emailError}
        helperText={emailError ? "Invalid email" : ""}
        placeholder="ENTER YOUR EMAIL"
        id="emailField"
        InputLabelProps={{
          style: textFieldLabelStyle,
        }}
        InputProps={{ style: textFieldStyle }}
        label="EMAIL"
        className="sign-in-email-input"
        variant="standard"
        onChange={handleInputChange("email")}
        onKeyDown={onInputKeyDown}
      />

      <FormControl
        error={passwordError}
        sx={{
          m: 1,
          width: "calc(100% - 100px)",
          marginLeft: "50px",
          marginRight: "50px",
          padding: "0px 10px",
          height: "40px",
          marginTop: "40px",
        }}
        variant="standard"
      >
        <InputLabel htmlFor="signInPasswordField" style={textFieldLabelStyle}>
          PASSWORD
        </InputLabel>
        <Input
          placeholder="ENTER YOUR PASSWORD"
          style={textFieldStyle}
          id="signInPasswordField"
          type={values.showPassword ? "text" : "password"}
          onChange={handleInputChange("password")}
          onKeyDown={onInputKeyDown}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                
              >
                {!values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText id="component-error-text" htmlFor="signInPasswordField">
          {passwordError ? "Invalid password" : ""}
        </FormHelperText>
      </FormControl>

      <div className="error-msg-div">
        <h4 className="error-msg" id="errorMsg">
          {errorMsg}
        </h4>
      </div>

      <button
        id="signInBtn"
        className="sign-in-btn"
        onClick={handleSignInClicked}
      >
        SIGN IN
      </button>
      <div style={{display: "inline-block", textAlign: "center", width: "100%"}}>
        <h4 className="forgot-password" onClick={forgotPasswordClicked}>
          FORGOT PASSWORD?
        </h4>
        </div>
    </ThemeProvider>
  );
};

export default SignIn;
