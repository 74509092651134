function ValidatePassword(password) {
  let checkPasswordLength = new RegExp("(?=.{8,})");
  let checkPasswordUppercase = new RegExp("(?=.*[A-Z])");
  let checkPasswordLowercase = new RegExp("(?=.*[a-z])");
  let checkPasswordNumber = new RegExp("(?=.*[0-9])");
  let checkPasswordSpecialChar = new RegExp("(?=.*[^A-Za-z0-9])");

  const pwordvalid = [
    checkPasswordLength.test(password),
    checkPasswordUppercase.test(password),
    checkPasswordLowercase.test(password),
    checkPasswordNumber.test(password),
    checkPasswordSpecialChar.test(password),
  ];

  const pwValid = pwordvalid.every((element) => element === true);

  return {
    pwLength: pwordvalid[0],
    pwUpper: pwordvalid[1],
    pwLower: pwordvalid[2],
    pwNumber: pwordvalid[3],
    pwSpecial: pwordvalid[4],
    pwValid: pwValid,
  }
}

export default ValidatePassword;
