import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  IconButton,
  Input,
  InputLabel,
  FormControl,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PasswordTooltip from "../../misc/PasswordTooltip";
import ValidatePassword from "../../misc/ValidatePassword";

const ChangePassword = ({ user, editInProgress, setEditInProgress }) => {
  //   ------------ State Initialization Stuff ------------
  const [passwordEditable, setPasswordEditable] = useState(false);

  const [passwordValues, setPasswordValues] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [passwordVisibility, setPasswordVisibility] = useState({
    oldPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  });

  const [passwordErrors, setPasswordErrors] = useState({
    oldPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  });

  const [passwordErrMsg, setPasswordErrMsg] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [passwordChangeSubmitErrorMsg, setPasswordChangeSubmitErrorMsg] =
    useState("");

  const [passwordChangeSuccessMsg, setPasswordChangeSubmitSuccessMsg] =
    useState("");

  const [showPasswordTooltip, setShowPasswordTooltip] = useState(false);

  //   ------------ Style Stuff ------------
  const passwordFieldLabelStyle = {
    padding: "0px 25px",
    height: "40px",
    fontSize: "20px",
    fontFamily: "FuturaBT-Book",
    boxShadow: "none",
    fontWeight: "bold",
    pointerEvents: passwordEditable ? "auto" : "none",
  };

  const passwordFieldStyle = {
    padding: "0px 25px",
    fontSize: "18px",
    fontFamily: "FuturaBT-Book",
    pointerEvents: passwordEditable ? "auto" : "none",
  };

  //   ------------ Callback Stuff ------------
  const handleChangePasswordClick = () => {
    setPasswordEditable(true);
    setEditInProgress(true);
    setTimeout(() => {
      document.getElementById("oldPasswordField").focus();
    }, "600");
  };

  const handleCancelChangePasswordClick = () => {
    setPasswordEditable(false);
    setEditInProgress(false);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, "75");
    setPasswordValues({
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    });
    setPasswordVisibility({
      oldPassword: false,
      newPassword: false,
      confirmNewPassword: false,
    });
    setPasswordErrors({
      oldPassword: false,
      newPassword: false,
      confirmNewPassword: false,
    });
    setPasswordErrMsg({
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    });
    setShowPasswordTooltip(false);
    setPasswordChangeSubmitErrorMsg("");
  };

  const handleShowPasswordClick = (prop) => () => {
    setPasswordVisibility({
      ...passwordVisibility,
      [prop]: !passwordVisibility[prop],
    });
  };

  const handlePasswordChange = (prop) => (event) => {
    setPasswordErrors({
      oldPassword: false,
      newPassword: false,
      confirmNewPassword: false,
    });
    setPasswordErrMsg({
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    });
    setShowPasswordTooltip(false);
    setPasswordChangeSubmitErrorMsg("");
    setPasswordValues({ ...passwordValues, [prop]: event.target.value });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmitChangePasswordClick = async () => {
    let errors = {
      oldPassword: false,
      newPassword: false,
      confirmNewPassword: false,
    };

    let errorMsgs = {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    };

    let crossCheckPassed = true;

    if (passwordValues.oldPassword.length < 1) {
      errors.oldPassword = true;
      errorMsgs.oldPassword = "*Required";
    }

    const { pwValid } = ValidatePassword(passwordValues.newPassword);
    if (passwordValues.newPassword.length < 1) {
      errors.newPassword = true;
      errorMsgs.newPassword = "*Required";
      crossCheckPassed = false;
    } else if (!pwValid) {
      errors.newPassword = true;
      setShowPasswordTooltip(true);
      errorMsgs.newPassword = "*Invalid password";
      crossCheckPassed = false;
    }

    if (passwordValues.confirmNewPassword.length < 1) {
      errors.confirmNewPassword = true;
      errorMsgs.confirmNewPassword = "*Required";
      crossCheckPassed = false;
    } else if (
      passwordValues.newPassword !== passwordValues.confirmNewPassword
    ) {
      errors.confirmNewPassword = true;
      errorMsgs.confirmNewPassword = "*Password mismatch";
      crossCheckPassed = false;
    }

    if (crossCheckPassed) {
      try {
        await Auth.changePassword(
          user,
          passwordValues.oldPassword,
          passwordValues.newPassword
        );
        setPasswordChangeSubmitSuccessMsg("CHANGE SUCCESSFUL!");
        setPasswordEditable(false);
        setEditInProgress(false);
      } catch (err) {
        if (err.message === "Incorrect username or password.") {
          errors.oldPassword = true;
          errorMsgs.oldPassword = "*Incorrect password";
        } else {
          setPasswordChangeSubmitErrorMsg(
            err.message
              .toUpperCase()
              .replace(".", "")
              .replace("USERNAME", "EMAIL")
          );
        }
        console.log(err);
      }
    }

    setPasswordErrors(errors);
    setPasswordErrMsg(errorMsgs);
  };

  useEffect(() => {
    if (editInProgress) {
      setPasswordChangeSubmitSuccessMsg("")
    }
  }, [editInProgress]);

  return (
    <div
      className="change-password-div"
      style={{ maxHeight: passwordEditable ? "520px" : "86px" }}
    >
      <div className="change-password-header-div">
        <h2 className="change-password-header">PASSWORD</h2>
        <IconButton
          sx={{
            marginBottom: "25px",
            marginTop: "25px",
            padding: "0px",
            marginLeft: "10px",
            width: "32px",
            transition: "opacity 0.3s linear",
          }}
          className={
            editInProgress ? "edit-icon-button disabled" : "edit-icon-button"
          }
          tabindex={editInProgress ? "-1" : "0"}
          size="large"
          onClick={handleChangePasswordClick}
        >
          <EditIcon className="edit-icon" />
        </IconButton>
        <span className="password-change-success">
          {passwordChangeSuccessMsg}
        </span>
      </div>

      <FormControl
        error={passwordErrors.oldPassword}
        sx={{
          m: 1,
          width: "calc(100% - 100px)",
          marginLeft: "50px",
          marginRight: "50px",
          padding: "0px 10px",
          height: "40px",
          marginTop: "10px",
          opacity: passwordEditable ? "100%" : "0%",
          transition: "opacity 0.6s linear",
        }}
        variant="standard"
      >
        <InputLabel htmlFor="oldPasswordField" style={passwordFieldLabelStyle}>
          OLD PASSWORD
        </InputLabel>
        <Input
          inputProps={{ tabindex: passwordEditable ? "0" : "-1" }}
          value={passwordValues.oldPassword}
          placeholder="ENTER YOUR OLD PASSWORD"
          style={passwordFieldStyle}
          id="oldPasswordField"
          type={passwordVisibility.oldPassword ? "text" : "password"}
          onChange={handlePasswordChange("oldPassword")}
          // onKeyDown={onInputKeyDown}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                tabindex={-1}
                onClick={handleShowPasswordClick("oldPassword")}
                onMouseDown={handleMouseDownPassword}
              >
                {!passwordVisibility.oldPassword ? (
                  <Visibility />
                ) : (
                  <VisibilityOff />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText id="component-error-text" htmlFor="oldPasswordField">
          {passwordErrors.oldPassword ? passwordErrMsg.oldPassword : ""}
        </FormHelperText>
      </FormControl>

      <FormControl
        error={passwordErrors.newPassword}
        sx={{
          m: 1,
          width: "calc(100% - 100px)",
          marginLeft: "50px",
          marginRight: "50px",
          padding: "0px 10px",
          height: "40px",
          marginTop: "40px",
          opacity: passwordEditable ? "100%" : "0%",
          transition: "opacity 0.6s linear",
        }}
        variant="standard"
      >
        <InputLabel htmlFor="newPasswordField" style={passwordFieldLabelStyle}>
          NEW PASSWORD
        </InputLabel>
        <Input
          inputProps={{ tabindex: passwordEditable ? "0" : "-1" }}
          value={passwordValues.newPassword}
          placeholder="ENTER YOUR NEW PASSWORD"
          style={passwordFieldStyle}
          id="newPasswordField"
          type={passwordVisibility.newPassword ? "text" : "password"}
          onChange={handlePasswordChange("newPassword")}
          //   onKeyDown={onInputKeyDown}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                tabindex={-1}
                onClick={handleShowPasswordClick("newPassword")}
                onMouseDown={handleMouseDownPassword}
              >
                {!passwordVisibility.newPassword ? (
                  <Visibility />
                ) : (
                  <VisibilityOff />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText id="component-error-text" htmlFor="newPasswordField">
          {passwordErrors.newPassword ? passwordErrMsg.newPassword : ""}
        </FormHelperText>
      </FormControl>

      <PasswordTooltip
        password={passwordValues.newPassword}
        opacity={showPasswordTooltip ? 100 : 0}
      />

      <FormControl
        error={passwordErrors.confirmNewPassword}
        sx={{
          m: 1,
          width: "calc(100% - 100px)",
          marginLeft: "50px",
          marginRight: "50px",
          padding: "0px 10px",
          height: "40px",
          marginTop: "40px",
          marginBottom: "15px",
          opacity: passwordEditable ? "100%" : "0%",
          transition: "opacity 0.6s linear",
        }}
        variant="standard"
      >
        <InputLabel
          htmlFor="confirmNewPasswordField"
          style={passwordFieldLabelStyle}
        >
          CONFIRM NEW PASSWORD
        </InputLabel>
        <Input
          inputProps={{ tabindex: passwordEditable ? "0" : "-1" }}
          value={passwordValues.confirmNewPassword}
          placeholder="CONFIRM YOUR NEW PASSWORD"
          style={passwordFieldStyle}
          id="confirmNewPasswordField"
          type={passwordVisibility.confirmNewPassword ? "text" : "password"}
          onChange={handlePasswordChange("confirmNewPassword")}
          //   onKeyDown={onInputKeyDown}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                tabindex={-1}
                onClick={handleShowPasswordClick("confirmNewPassword")}
                onMouseDown={handleMouseDownPassword}
              >
                {!passwordVisibility.confirmNewPassword ? (
                  <Visibility />
                ) : (
                  <VisibilityOff />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText
          id="component-error-text"
          htmlFor="confirmNewPasswordField"
        >
          {passwordErrors.confirmNewPassword
            ? passwordErrMsg.confirmNewPassword
            : ""}
        </FormHelperText>
      </FormControl>

      <div className="change-password-error-msg-div">
        <h4 className="change-password-error-msg" id="errorMsg">
          {passwordChangeSubmitErrorMsg}
        </h4>
      </div>

      <button
        tabindex={passwordEditable ? "0" : "-1"}
        id="changePasswordBtn"
        className={
          passwordEditable
            ? "save-account-info-btn"
            : "save-account-info-btn disabled"
        }
        onClick={handleSubmitChangePasswordClick}
      >
        CHANGE PASSWORD
      </button>

      <Button
        tabindex={passwordEditable ? "0" : "-1"}
        className={
          passwordEditable ? "cancel-changes" : "cancel-changes disabled"
        }
        sx={{
          borderRadius: "0px",
          paddingLeft: "auto",
          paddingRight: "auto",
          left: "50%",
          transform: "translateX(-50%)",
          paddingTop: "25px",
          paddingBottom: "35px",
          margin: "0px",
          fontFamily: "FuturaBT-Book",
          fontSize: "16px",
          opacity: passwordEditable ? "100%" : "0%",
          transition: "opacity 0.6s linear",
          ":hover": { textDecoration: "underline" },
        }}
        onClick={handleCancelChangePasswordClick}
      >
        DISCARD CHANGES
      </Button>
    </div>
  );
};

export default ChangePassword;
