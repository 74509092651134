// Regular react stuff

// Amplify stuff
import { Amplify, Auth, Hub } from "aws-amplify";
import awsconfig from "./aws-exports";
import "@aws-amplify/ui-react/styles.css";
import Authenticator from "./components/Authentication/Authenticator";

import "./App.css";
import React, { useState, useEffect } from "react";
// import FileExplorer from "./components/Body/FileExplorer";
import SignedInContent from "./components/Body/SignedInContent";
import NavBar from "./components/Header/NavBar";
// import { Height } from "@mui/icons-material";

Amplify.configure(awsconfig);

window.onbeforeunload = function () {
  window.history.scrollRestoration = "manual";
  window.scrollTo(0, 0);
};

function App() {
  // const [signedIn, setSignedIn] = useState(false);
  const [mainContent, setMainContent] = useState(<Authenticator />);

  const [user, setUser] = useState(null);

  const [signedInMainContentType, setSignedInMainContentType] =
    useState("Downloads");

  useEffect(() => {
    let updateUser = async (authState) => {
      try {
        let user = await Auth.currentAuthenticatedUser();
        setUser(user);
      } catch {
        setUser(null);
      }
    };
    const hubListenerCancelToken = Hub.listen("auth", updateUser); // listen for login/signup events
    updateUser(); // check manually the first time because we won't get a Hub event
    return () => hubListenerCancelToken(); // cleanup
  }, []);

  useEffect(() => {
    setMainContent(
      user ? (
        <SignedInContent
          setSignedInMainContentType={setSignedInMainContentType}
          signedInMainContentType={signedInMainContentType}
          user={user}
        />
      ) : (
        <Authenticator />
      )
    );
  }, [user, signedInMainContentType]);

  return (
    <main>
      <NavBar
        user={user}
        setSignedInMainContentType={setSignedInMainContentType}
      />
      {mainContent}
      <div className="footer">
        <span>© Copyright Magnitude Instruments 2023</span>
      </div>
    </main>
  );
}

export default App;
