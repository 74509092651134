import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, FormControl, TextField, Button } from "@mui/material";

import CircleCheck from "../../misc/CircleCheck";
import CircleXMark from "../../misc/CircleXMark";
import ValidateEmail from "../../misc/ValidateEmail";

const ChangeEmail = ({ user, editInProgress, setEditInProgress }) => {
  //   ------------ State Initialization Stuff ------------
  const [emailEditable, setEmailEditable] = useState(false);
  const [emailBeingVerified, setEmailBeingVerified] = useState(false);
  const [verifyEmailQHidden, setVerifyEmailQHidden] = useState(false);

  const [emailFieldValue, setEmailFieldValue] = useState(
    user.attributes["email"]
  );

  const [emailFieldError, setEmailFieldError] = useState(false);
  const [emailFieldErrorMsg, setEmailFieldErrorMsg] = useState("");

  const [verificationCode, setVerificationCode] = useState("");

  const [verificationCodeFieldError, setVerificationCodeError] =
    useState(false);
  const [verificationCodeFieldErrorMsg, setVerificationCodeErrorMsg] =
    useState("");

  const [emailChangeVerifyErrorMsg, setEmailChangeVerifyErrorMsg] =
    useState("");

  const [cancelChangeMsg, setCancelChangeMsg] = useState("DISCARD CHANGES");

  const [emailChangedVerifiedSuccessMsg, setEmailChangedVerifiedSuccessMsg] =
    useState("");

  //   ------------ Style Stuff ------------

  const emailFieldLabelStyle = {
    padding: "0px 25px",
    height: "40px",
    fontSize: "20px",
    fontFamily: "FuturaBT-Book",
    boxShadow: "none",
    fontWeight: "bold",
    pointerEvents: emailEditable ? "auto" : "none",
  };

  const emailFieldStyle = {
    padding: "0px 25px",
    fontSize: "18px",
    fontFamily: "FuturaBT-Book",
    pointerEvents: emailEditable ? "auto" : "none",
  };

  const verificationCodeLabelStyle = {
    padding: "0px 25px",
    height: "40px",
    fontSize: "20px",
    fontFamily: "FuturaBT-Book",
    boxShadow: "none",
    fontWeight: "bold",
    pointerEvents: emailBeingVerified ? "auto" : "none",
  };

  const verificationCodeFieldStyle = {
    padding: "0px 25px",
    fontSize: "18px",
    fontFamily: "FuturaBT-Book",
    pointerEvents: emailBeingVerified ? "auto" : "none",
  };

  //   ------------ Callback Stuff ------------ //
  const handleEditEmailIconClicked = () => {
    setCancelChangeMsg("DISCARD CHANGES");
    setEditInProgress(true);
    setEmailEditable(true);
  };

  const handleVerifyEmailClick = async () => {
    document.getElementById("verificationCodeForm").style.display =
      "inline-flex";
    setEditInProgress(true);
    setCancelChangeMsg("CANCEL VERIFICATION");
    setEmailBeingVerified(true);

    // === send email verification code === //

    try {
      await Auth.verifyCurrentUserAttribute("email");
    } catch (err) {
      setEmailChangeVerifyErrorMsg(
        err.message.toUpperCase().replace(".", "").replace("USERNAME", "EMAIL")
      );
    }
  };

  const handleEmailChange = (event) => {
    setEmailFieldError(false);
    setEmailFieldErrorMsg("");
    setEmailFieldValue(event.target.value);
  };

  const handleSubmitChangeEmailClick = async () => {
    // ===== Change States ===== //
    setEmailChangedVerifiedSuccessMsg("");
    setVerifyEmailQHidden(true);
    setEmailEditable(false);
    setEmailChangeVerifyErrorMsg("");
    setEmailFieldError(false);
    setEmailFieldErrorMsg("");

    let crossCheckPassed = true;

    // ===== Validate Email ===== //
    if (emailFieldValue.length < 1) {
      setEmailFieldError(true);
      setEmailFieldErrorMsg("*Required");
      crossCheckPassed = false;
    } else if (!ValidateEmail(emailFieldValue)) {
      setEmailFieldError(true);
      setEmailFieldErrorMsg("*Invalid");
      crossCheckPassed = false;
    }

    if (crossCheckPassed) {
      try {
        // ===== Change Email Request ===== //
        await Auth.updateUserAttributes(user, {
          email: emailFieldValue,
        });

        // ===== Set up email verification ===== //
        setTimeout(() => {
          document.getElementById("verificationCodeForm").style.display =
            "inline-flex";
          setEmailBeingVerified(true);
          setCancelChangeMsg("SKIP VERIFICATION");
          setVerifyEmailQHidden(false);
          setEmailChangedVerifiedSuccessMsg("CHANGE SUCCESSFUL!");
        }, "600");
      } catch (err) {
        console.log(err);
        setEmailEditable(true);
        setEmailChangeVerifyErrorMsg(
          err.message
            .toUpperCase()
            .replace(".", "")
            .replace("USERNAME", "EMAIL")
        );
      }
    }
  };

  const onEmailFieldKeyDown = () => {
    handleSubmitChangeEmailClick();
  };

  const handleCodeFocus = (event) => {
    if (event.target.value.length === 0) {
      setVerificationCode("");
    }
  };

  const handleCodeBlur = (event) => {
    if (event.target.value.length === 0) {
      setVerificationCode(null);
    }
  };

  const handleVerificationCodeChange = (event) => {
    setEmailChangeVerifyErrorMsg("");
    setVerificationCodeError(false);
    setVerificationCodeErrorMsg("");

    let checkPasswordSpecialChar = new RegExp("(?=.*[^A-Za-z0-9])");
    let checkPasswordUppercase = new RegExp("(?=.*[A-Z])");
    let checkPasswordLowercase = new RegExp("(?=.*[a-z])");

    if (
      (checkPasswordSpecialChar.test(event.nativeEvent.data) ||
        checkPasswordUppercase.test(event.nativeEvent.data) ||
        checkPasswordLowercase.test(event.nativeEvent.data)) &&
      event.nativeEvent.data !== null
    ) {
      if (event.target.value.length - 1 === 0) {
        setVerificationCode("");
        event.target.value = "";
      } else {
        event.target.value = verificationCode;
      }
    } else {
      if (event.target.value.length === 0) {
        setVerificationCode("");
        event.target.value = "";
      } else {
        setVerificationCode(event.target.value);
      }
    }
  };

  const handleSubmitVerificationCodeClick = async () => {
    setEmailChangedVerifiedSuccessMsg("");
    setEmailChangeVerifyErrorMsg("");
    setVerificationCodeError(false);
    setVerificationCodeErrorMsg("");

    let crossCheckPassed = true;

    if (verificationCode.length < 1) {
      setVerificationCodeError(true);
      setVerificationCodeErrorMsg("*Required");
      crossCheckPassed = false;
    }

    if (crossCheckPassed) {
      try {
        await Auth.verifyCurrentUserAttributeSubmit("email", verificationCode);

        await Auth.updateUserAttributes(user, {
          email: emailFieldValue,
        }).then(() => {
          setEmailBeingVerified(false);
          setEditInProgress(false);
          setTimeout(() => {
            setEmailChangedVerifiedSuccessMsg("EMAIL VERIFIED!");
            setVerificationCode("");
            document.getElementById("verificationCodeForm").style.display =
              "none";
          }, "600");
        });
      } catch (err) {
        setEmailChangeVerifyErrorMsg(
          err.message
            .toUpperCase()
            .replace(".", "")
            .replace("USERNAME", "EMAIL")
        );
      }
    }
  };

  const onVerificationFieldKeyDown = () => {
    handleSubmitVerificationCodeClick();
  };

  const handleCancelChangeEmailClick = () => {
    setEmailBeingVerified(false);
    setEmailEditable(false);
    setEditInProgress(false);
    setEmailFieldError(false);
    setVerificationCodeError(false);
    setEmailFieldErrorMsg("");
    setEmailChangeVerifyErrorMsg("");
    setTimeout(() => {
      document.getElementById("verificationCodeForm").style.display = "none";
    }, "700");
    setEmailFieldValue(user.attributes.email);
    setVerificationCode("");
  };

  // ===== EFFECTS ===== //
  useEffect(() => {
    setEmailChangedVerifiedSuccessMsg("");
  }, [editInProgress]);

  // ===== RETURN ===== //
  return (
    <div
      className="email-div"
      id="emailDiv"
      style={{
        maxHeight: emailEditable
          ? "390px"
          : emailBeingVerified
          ? "460px"
          : "225px",
      }}
    >
      <div className="email-header-div">
        <h2 className="email-header">EMAIL</h2>
        <IconButton
          sx={{
            marginBottom: "25px",
            marginTop: "25px",
            padding: "0px",
            marginLeft: "10px",
            width: "32px",
            transition: "opacity 0.3s linear",
          }}
          className={
            editInProgress ? "edit-icon-button disabled" : "edit-icon-button"
          }
          tabindex={editInProgress ? "-1" : "0"}
          size="large"
          onClick={handleEditEmailIconClicked}
        >
          <EditIcon className="edit-icon" />
        </IconButton>
        <span className="password-change-success">
          {emailChangedVerifiedSuccessMsg}
        </span>
      </div>

      <FormControl
        sx={{
          m: 1,
          width: "calc(100% - 100px)",
          marginLeft: "50px",
          marginRight: "50px",
          padding: "0px 10px",
          height: "40px",
          marginTop: "0px",
          marginBottom: "30px",
        }}
        variant="standard"
      >
        <TextField
          value={emailFieldValue}
          error={emailFieldError}
          helperText={emailFieldError ? emailFieldErrorMsg : ""}
          placeholder="ENTER YOUR EMAIL"
          id="emailField"
          InputLabelProps={{
            style: emailFieldLabelStyle,
          }}
          InputProps={{
            style: emailFieldStyle,
            readOnly: !emailEditable,
          }}
          inputProps={{ tabindex: emailEditable ? "0" : "-1" }}
          label="EMAIL"
          variant="standard"
          onChange={handleEmailChange}
          onKeyDown={onEmailFieldKeyDown}
        />
      </FormControl>
      <div className="email-verified-div">
        <span className="email-verified">EMAIL VERIFIED:</span>
        {user.attributes.email_verified ? (
          <CircleCheck
            height="18px"
            width="18px"
            fill="#009E60"
            className="email-verified-check"
          />
        ) : (
          <CircleXMark
            height="18px"
            width="18px"
            fill="#D22B2B"
            className="email-verified-check"
          />
        )}
        <Button
          tabindex="0"
          sx={{
            borderRadius: "0px",
            marginLeft: "15px",
            fontFamily: "FuturaBT-Book",
            fontSize: "15px",
            color: "black",
            cursor:
              emailEditable ||
              emailBeingVerified ||
              verifyEmailQHidden ||
              user.attributes.email_verified
                ? "default"
                : "pointer",
            textTransform: "lowercase",
            opacity: emailBeingVerified
              ? "100%"
              : emailEditable ||
                verifyEmailQHidden ||
                user.attributes.email_verified
              ? "0%"
              : "100%",
            pointerEvents:
              emailEditable ||
              emailBeingVerified ||
              verifyEmailQHidden ||
              user.attributes.email_verified
                ? "none"
                : "auto",
            userSelect:
              emailEditable ||
              emailBeingVerified ||
              verifyEmailQHidden ||
              user.attributes.email_verified
                ? "none"
                : "auto",
            visibility: emailBeingVerified
              ? "visible"
              : emailEditable ||
                verifyEmailQHidden ||
                user.attributes.email_verified
              ? "hidden"
              : "visible",
            transition: "opacity 1s linear",
            ":hover": { textDecoration: "underline", color: "#257ce1" },
          }}
          onClick={handleVerifyEmailClick}
        >
          {emailBeingVerified ? "Code sent to email" : "verify email?"}
        </Button>
      </div>

      <FormControl
        sx={{
          m: 1,
          width: "calc(100% - 100px)",
          marginLeft: "50px",
          marginRight: "50px",
          // padding: "0px 10px",
          padding: "0px 10px",
          height: "40px",
          marginTop: "0px",
          marginBottom: "30px",
          display: "none",
          maxHeight: emailBeingVerified ? "40px" : "0px",
          opacity: emailBeingVerified ? "100%" : "0%",
          pointerEvents: emailBeingVerified ? "auto" : "none",
          cursor: emailBeingVerified ? "text" : "none",
          transition: "opacity 0.6s linear, max-height 0.6s ease-in-out",
          // position: emailBeingVerified ? "relative" : "absolute",
          // display: emailBeingVerified ? "auto" : "none",
        }}
        variant="standard"
        id="verificationCodeForm"
      >
        <TextField
          value={verificationCode}
          error={verificationCodeFieldError}
          helperText={
            verificationCodeFieldError ? verificationCodeFieldErrorMsg : ""
          }
          placeholder="ENTER VERIFICATION CODE"
          id="verificationCodeField"
          InputLabelProps={{
            style: verificationCodeLabelStyle,
          }}
          InputProps={{
            style: verificationCodeFieldStyle,
            readOnly: !emailBeingVerified,
          }}
          inputProps={{ tabindex: emailBeingVerified ? "0" : "-1" }}
          label="VERIFCATION CODE"
          variant="standard"
          onChange={handleVerificationCodeChange}
          onFocus={handleCodeFocus}
          onBlur={handleCodeBlur}
          onKeyDown={onVerificationFieldKeyDown}
        />
      </FormControl>

      <div className="change-email-error-msg-div">
        <h4 className="change-email-error-msg" id="emailErrorMsg">
          {emailChangeVerifyErrorMsg}
        </h4>
      </div>
      <div>
        <button
          tabindex={emailEditable || emailBeingVerified ? "0" : "-1"}
          id="changeEmailBtn"
          className={
            emailEditable || emailBeingVerified
              ? "save-account-info-btn"
              : "save-account-info-btn disabled"
          }
          onClick={
            emailBeingVerified
              ? handleSubmitVerificationCodeClick
              : handleSubmitChangeEmailClick
          }
        >
          {emailBeingVerified ? "VERIFY EMAIL" : "CHANGE EMAIL"}
        </button>

        <Button
          tabindex={emailEditable || emailBeingVerified ? "0" : "-1"}
          className={
            emailEditable || emailBeingVerified
              ? "cancel-changes"
              : "cancel-changes disabled"
          }
          sx={{
            borderRadius: "0px",
            paddingLeft: "auto",
            paddingRight: "auto",
            left: "50%",
            transform: "translateX(-50%)",
            paddingTop: "25px",
            paddingBottom: "35px",
            margin: "0px",
            fontFamily: "FuturaBT-Book",
            fontSize: "16px",
            ":hover": { textDecoration: "underline" },
          }}
          onClick={handleCancelChangeEmailClick}
        >
          {cancelChangeMsg}
        </Button>
      </div>
    </div>
  );
  // //   ------------ State Initialization Stuff ------------
  // const [emailEditable, setEmailEditable] = useState(false);
  // const [emailBeingVerified, setEmailBeingVerified] = useState(false);

  // const [emailFieldValue, setEmailFieldValue] = useState(
  //   user.attributes["email"]
  // );
  // const [emailError, setEmailError] = useState(false);
  // const [emailErrMsg, setEmailErrMsg] = useState("");
  // const [emailChangeSubmitErrorMsg, setEmailChangeSubmitErrorMsg] =
  //   useState("");
  // const [emailChangeSuccessMsg, setEmailChangeSuccessMsg] = useState("");

  // //   ------------ Style Stuff ------------

  // const emailFieldLabelStyle = {
  //   padding: "0px 25px",
  //   height: "40px",
  //   fontSize: "20px",
  //   fontFamily: "FuturaBT-Book",
  //   boxShadow: "none",
  //   fontWeight: "bold",
  //   pointerEvents: emailEditable ? "auto" : "none",
  // };

  // const emailCodeLabelStyle = {
  //   padding: emailBeingVerified ? "0px 25px" : "0px",
  //   height: "40px",
  //   fontSize: emailBeingVerified ? "20px" : "0px",
  //   fontFamily: "FuturaBT-Book",
  //   boxShadow: "none",
  //   fontWeight: "bold",
  //   pointerEvents: emailBeingVerified ? "auto" : "none",
  //   maxHeight: emailBeingVerified ? "40px" : "0px",
  // };

  // const emailFieldStyle = {
  //   padding: "0px 25px",
  //   fontSize: "18px",
  //   fontFamily: "FuturaBT-Book",
  //   pointerEvents: emailEditable ? "auto" : "none",
  // };

  // const emailCodeFieldStyle = {
  //   padding: emailBeingVerified ? "0px 25px" : "0px",
  //   fontSize: emailBeingVerified ? "18px" : "0px",
  //   fontFamily: "FuturaBT-Book",
  //   pointerEvents: emailBeingVerified ? "auto" : "none",
  //   maxHeight: emailBeingVerified ? "40px" : "0px",
  // };

  // //   ------------ Callback Stuff ------------
  // const handleEditEmailIconClicked = () => {
  //   setEmailChangeSuccessMsg("");
  //   setEmailEditable(true);
  //   document.getElementById("emailField").focus();
  // };

  // const handleCancelChangeEmailClick = () => {
  //   setEmailErrMsg("");
  //   setEmailError(false);
  //   setEmailChangeSubmitErrorMsg("");
  //   setEmailEditable(false);
  //   setEmailFieldValue(user.attributes["email"]);
  // };

  // const handleEmailChange = (event) => {
  //   setEmailError(false);
  //   setEmailErrMsg("");
  //   setEmailChangeSubmitErrorMsg("");
  //   setEmailFieldValue(event.target.value);
  // };

  // const handleSubmitChangeEmailClick = () => {
  //   setEmailErrMsg("");
  //   setEmailChangeSubmitErrorMsg("");

  //   let crossCheckPassed = true;

  //   if (emailFieldValue.length < 1) {
  //     setEmailError(true);
  //     setEmailErrMsg("*Required");
  //     crossCheckPassed = false;
  //   } else if (!ValidateEmail(emailFieldValue)) {
  //     setEmailError(true);
  //     setEmailErrMsg("*Invalid");
  //     crossCheckPassed = false;
  //   }

  //   if (crossCheckPassed) {
  //     setEmailBeingVerified(true);
  //   }
  // };

  // const handleVerifyEmailQClick = () => {
  //   setEmailBeingVerified(true);
  //   setEmailEditable(true);
  // };

  // const handleSkipEmailVerificationClick = () => {
  //   setEmailEditable(false);
  //   setEmailBeingVerified(false);
  // };

  // useEffect(() => {
  //   if (editInProgress) {
  //       setEmailChangeSuccessMsg("");
  //   }
  // }, [editInProgress]);

  // return (
  //   <div
  //     className="email-div"
  //     id="emailDiv"
  //     style={{
  //       maxHeight: emailEditable
  //         ? emailBeingVerified
  //           ? "450px"
  //           : "380px"
  //         : "225px",
  //     }}
  //   >
  //     <div className="email-header-div">
  //       <h2 className="email-header">EMAIL</h2>
  //       <IconButton
  //         sx={{
  //           marginBottom: "25px",
  //           marginTop: "25px",
  //           padding: "0px",
  //           marginLeft: "10px",
  //           width: "24px",
  //           transition: "opacity 0.3s linear",
  //         }}
  //         className={
  //           editInProgress ? "edit-icon-button disabled" : "edit-icon-button"
  //         }
  //         tabindex={editInProgress ? "-1" : "0"}
  //         size="large"
  //         onClick={handleEditEmailIconClicked}
  //       >
  //         <EditIcon className="edit-icon" />
  //       </IconButton>
  //     </div>
  //     <FormControl
  //       sx={{
  //         m: 1,
  //         width: "calc(100% - 100px)",
  //         marginLeft: "50px",
  //         marginRight: "50px",
  //         padding: "0px 10px",
  //         height: "40px",
  //         marginTop: "0px",
  //         marginBottom: "30px",
  //       }}
  //       variant="standard"
  //     >
  //       <TextField
  //         value={emailFieldValue}
  //         error={emailError}
  //         helperText={emailError ? emailErrMsg : ""}
  //         placeholder="ENTER YOUR EMAIL"
  //         id="emailField"
  //         InputLabelProps={{
  //           style: emailFieldLabelStyle,
  //         }}
  //         InputProps={{
  //           style: emailFieldStyle,
  //           readOnly: !emailEditable,
  //         }}
  //         inputProps={{ tabindex: emailEditable ? "0" : "-1" }}
  //         label="EMAIL"
  //         variant="standard"
  //         onChange={handleEmailChange}
  //         //   onKeyDown={onInputKeyDown}
  //       />
  //     </FormControl>
  //     <div className="email-verified-div">
  //       <span className="email-verified">EMAIL VERIFIED:</span>
  //       {false ? ( //user.attributes.email_verified ? (
  //         <CircleCheck
  //           height="18px"
  //           width="18px"
  //           fill="#009E60"
  //           className="email-verified-check"
  //         />
  //       ) : (
  //         <>
  //           <CircleXMark
  //             height="18px"
  //             width="18px"
  //             fill="#D22B2B"
  //             className="email-verified-check"
  //           />
  //           <span
  //             className="verify-email-question"
  //             tabindex="0"
  //             onClick={handleVerifyEmailQClick}
  //             style={{
  //               opacity: emailEditable || emailBeingVerified ? "0%" : "100%",
  //               pointerEvents:
  //                 emailEditable || emailBeingVerified ? "none" : "auto",
  //             }}
  //           >
  //             verifiy email?
  //           </span>
  //         </>
  //       )}
  //     </div>

  //     <FormControl
  //       sx={{
  //         m: 1,
  //         width: "calc(100% - 100px)",
  //         marginLeft: "50px",
  //         marginRight: "50px",
  //         padding: emailBeingVerified ? "0px 10px" : "0px",
  //         height: "40px",
  //         marginTop: "0px",
  //         marginBottom: emailBeingVerified ? "30px" : "0px",
  //         opacity: emailBeingVerified ? "100%" : "0%",
  //         transition: "opacity 0.6s linear",
  //         maxHeight: emailBeingVerified ? "40px" : "0px",
  //       }}
  //       variant="standard"
  //     >
  //       <TextField
  //         // error={emailError}
  //         // helperText={emailError ? emailErrMsg : ""}
  //         placeholder="ENTER EMAIL VERIFICATION CODE"
  //         id="emailVerificationCodeField"
  //         InputLabelProps={{
  //           style: emailCodeLabelStyle,
  //         }}
  //         InputProps={{
  //           style: emailCodeFieldStyle,
  //         }}
  //         inputProps={{ tabindex: emailBeingVerified ? "0" : "-1" }}
  //         label="VERIFICATION CODE"
  //         variant="standard"
  //         // onChange={handleEmailChange}
  //         //   onKeyDown={onInputKeyDown}
  //       />
  //     </FormControl>

  //     <div
  //       className="change-email-error-msg-div"
  //       style={{ maxHeight: emailBeingVerified ? "30px" : "0px" }}
  //     >
  //       <h4 className="change-email-error-msg" id="emailErrorMsg">
  //         {emailChangeSubmitErrorMsg}
  //       </h4>
  //     </div>

  //     <button
  //       tabindex={emailBeingVerified ? "0" : "-1"}
  //       id="changeEmailBtn"
  //       className={
  //         emailBeingVerified
  //           ? "save-account-info-btn"
  //           : "save-account-info-btn disabled"
  //       }
  //       style={{ maxHeight: emailBeingVerified ? "50px" : "0px" }}
  //       // onClick={handleSubmitChangeEmailClick}
  //     >
  //       VERIFY EMAIL
  //     </button>

  //     <Button
  //       tabindex={emailBeingVerified ? "0" : "-1"}
  //       className={
  //         emailBeingVerified ? "cancel-changes" : "cancel-changes disabled"
  //       }
  //       sx={{
  //         borderRadius: "0px",
  //         paddingLeft: "auto",
  //         paddingRight: "auto",
  //         left: "50%",
  //         transform: "translateX(-50%)",
  //         paddingTop: emailBeingVerified ? "25px" : "0px",
  //         paddingBottom: emailBeingVerified ? "35px" : "0px",
  //         margin: "0px",
  //         fontFamily: "FuturaBT-Book",
  //         fontSize: "16px",
  //         maxHeight: emailBeingVerified ? "28px" : "0px",
  //         ":hover": { textDecoration: "underline" },
  //       }}
  //       onClick={handleSkipEmailVerificationClick}
  //     >
  //       SKIP VERIFICATION
  //     </Button>

  //     <div className="change-email-error-msg-div">
  //       <h4 className="change-email-error-msg" id="emailErrorMsg">
  //         {emailChangeSubmitErrorMsg}
  //       </h4>
  //     </div>
  //     <button
  //       tabindex={emailEditable ? "0" : "-1"}
  //       id="changeEmailBtn"
  //       className={
  //         emailEditable
  //           ? "save-account-info-btn"
  //           : "save-account-info-btn disabled"
  //       }
  //       onClick={handleSubmitChangeEmailClick}
  //     >
  //       CHANGE EMAIL
  //     </button>

  //     <Button
  //       tabindex={emailEditable ? "0" : "-1"}
  //       className={emailEditable ? "cancel-changes" : "cancel-changes disabled"}
  //       sx={{
  //         borderRadius: "0px",
  //         paddingLeft: "auto",
  //         paddingRight: "auto",
  //         left: "50%",
  //         transform: "translateX(-50%)",
  //         paddingTop: "25px",
  //         paddingBottom: "35px",
  //         margin: "0px",
  //         fontFamily: "FuturaBT-Book",
  //         fontSize: "16px",
  //         ":hover": { textDecoration: "underline" },
  //       }}
  //       onClick={handleCancelChangeEmailClick}
  //     >
  //       DISCARD CHANGES
  //     </Button>
  //   </div>
  // );
};

export default ChangeEmail;
