import React, { useState, useEffect } from "react";
import { Auth, Hub } from "aws-amplify";
import "../styles/NavBar.css";
import Chevron from "../misc/Chevron";

import magLogo from "../../img/MI_New_x77.png";

function NavBar({ user, setSignedInMainContentType }) {
  // Style object for the signout button
  const [isHover, setIsHover] = useState(false);
  const [signedIn, setSignedIn] = useState(null);
  const [isAccountMenuActive, setIsAccountMenuActive] = useState(false);

  useEffect(() => {
    let updateSignedIn = async (authState) => {
      try {
        await Auth.currentAuthenticatedUser();
        setSignedIn(true);
      } catch {
        setSignedIn(false);
      }
    };
    const hubListenerCancelToken = Hub.listen("auth", updateSignedIn); // listen for login/signup events
    updateSignedIn(); // check manually the first time because we won't get a Hub event
    return () => hubListenerCancelToken(); //Hub.remove('auth', updateUser) // cleanup
  }, []);

  useEffect(() => {
    if (signedIn) {
      document.getElementById("accountMenuDiv").style.transition =
        "max-height 0.6s ease-in-out";
      document.getElementById("accountMenuBtn").style.transition =
        "color 0.2s linear, font-weight 0.2s linear, visibility 0.2s linear, background-color 0.2s linear";
    } else {
      document.getElementById("accountMenuDiv").style.transition = "none";
      document.getElementById("accountMenuBtn").style.transition = "none";
    }
  }, [signedIn]);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const accountMenuBlur = () => {
    setIsAccountMenuActive(false);
    // console.log(document.activeElement);
  };

  // const elementNames = [
  //   "HOME",
  //   "PRODUCTS",
  //   "APPLICATIONS",
  //   "EVENTS",
  //   "ABOUT US",
  //   "CONTACT US",
  // ];

  window.onscroll = function () {
    scrollFunction();
  };

  async function signOut() {
    try {
      setIsAccountMenuActive(false);
      document.getElementById("accountMenuDiv").style.transition = "none";
      document.getElementById("accountMenuBtn").style.transition = "none";
      await Auth.signOut();
      setSignedIn(false);
    } catch (error) {
      console.log("error signing out: ", error);
    }
    setSignedInMainContentType("Downloads");
    window.scrollTo(0, 0);
  }

  function handleAccountClick() {
    setIsAccountMenuActive(!isAccountMenuActive);
  }

  function handleAccountSettingsClick() {
    setSignedInMainContentType("EditAccount");
  }

  const menuWidth = () => {
    var elem = document.getElementById("accountMenuBtn");
    if (elem) {
      return elem.offsetWidth;
    } else {
      return "125px";
    }
  };

  function scrollFunction() {
    if (
      // document.body.scrollTop > 80 ||
      document.documentElement.scrollTop > 80
    ) {
      if (
        // document.body.scrollTop < 130 &&
        document.documentElement.scrollTop < 130
      ) {
        const pos = document.documentElement.scrollTop - 80;
        const ht = `${115 - pos}px`;
        const imgHt = `${77 - pos * (22 / 50)}px`;
        const topImgMarg = `${16 - pos * (11 / 50)}px`;
        const botImgMarg = `${22 - pos * (17 / 50)}px`;

        document.getElementById("navbar").style.height = ht;
        document.getElementById("outerDiv").style.height = ht;
        document.getElementById("innerDiv").style.height = ht;
        document.getElementById("accountMenuBtnDiv").style.height = ht;
        document.getElementById("accountMenuBtn").style.height = ht;
        document.getElementById("navMenu").style.height = ht;
        document.getElementById("magLogoImg").style.height = imgHt;
        document.getElementById("magLogoDiv").style.marginTop = topImgMarg;
        document.getElementById("magLogoDiv").style.marginBottom = botImgMarg;
        document.getElementById("accountMenuDiv").style.top = ht;
      } else {
        document.getElementById("navbar").style.height = "65px";
        document.getElementById("outerDiv").style.height = "65px";
        document.getElementById("innerDiv").style.height = "65px";
        document.getElementById("accountMenuBtnDiv").style.height = "65px";
        document.getElementById("accountMenuBtn").style.height = "65px";
        document.getElementById("navMenu").style.height = "65px";
        document.getElementById("magLogoImg").style.height = "55px";
        document.getElementById("magLogoDiv").style.marginTop = "5px";
        document.getElementById("magLogoDiv").style.marginBottom = "5px";
        document.getElementById("accountMenuDiv").style.top = "65px";
        // document.getElementById("navbar").style.boxShadow =
        //   "0 2px 4px 0 rgba(0,0,0,.1)";
      }
    } else {
      document.getElementById("navbar").style.height = "115px";
      document.getElementById("outerDiv").style.height = "115px";
      document.getElementById("innerDiv").style.height = "115px";
      document.getElementById("accountMenuBtnDiv").style.height = "115px";
      document.getElementById("accountMenuBtn").style.height = "115px";
      document.getElementById("navMenu").style.height = "115px";
      document.getElementById("magLogoImg").style.height = "77px";
      document.getElementById("magLogoDiv").style.marginTop = "16px";
      document.getElementById("magLogoDiv").style.marginBottom = "22px";
      document.getElementById("accountMenuDiv").style.top = "115px";
      // document.getElementById("navbar").style.boxShadow = null;
    }
  }

  const accountMenuBtnStyle = {
    color: isHover || isAccountMenuActive ? "white" : "#666666",
    // fontWeight: isHover || isAccountMenuActive ? "bold" : "normal",
    backgroundColor: isHover || isAccountMenuActive ? "#257ce1" : "white",
    height: "115px",
    // width: "100px",
    paddingRight: 20,
    paddingLeft: 40,
    marginBottom: "auto",
    marginTop: "auto",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    // transitionDuration: "0.2s",
    // transitionTimingFunction: "linear",
    // transition: "background 2s linear",
    // "color 0.2s linear, background-color 2s linear, visibility 0.2s linear",
    cursor: signedIn ? "pointer" : "default",
    fontSize: "17px",
    visibility: signedIn ? "visible" : "hidden",
    userSelect: "none",
    fontFamily: "FuturaBT-Book",
    border: "none",
    minWidth: "215px",
  };

  return (
    <>
      <div className="navBarDiv" id="navbar">
        <div
          style={{
            height: 115,
            overflow: "visible",
            background: "white",
            // transition: "0.4s",
          }}
          id="outerDiv"
        >
          <div
            id="innerDiv"
            style={{
              background: "white",
              height: 115,
              paddingLeft: 30,
              paddingRight: 30,
              // transition: "0.4s",
            }}
          >
            <div className="row" style={{ paddingTop: 0, paddingBottom: 0 }}>
              <div id="magLogoDiv" className="logoDiv">
                <a href="https://magnitudeinstruments.com/" tabindex="-1">
                  <img
                    id="magLogoImg"
                    src={magLogo}
                    className="logoImg"
                    alt="Magnitude Logo"
                  />
                </a>
              </div>

              <nav className="nav-menu" id="navMenu">
                <ul id="navUL">
                  <li id="navLI1">
                    <a
                      id="navA1"
                      href="https://magnitudeinstruments.com/"
                      tabindex="-1"
                    >
                      <span id="navSpan1">HOME</span>
                    </a>
                  </li>
                  <li id="navLI2">
                    <a id="navA2" className="selected" href="/" tabindex="-1">
                      <span id="navSpan2">DOWNLOADS</span>
                    </a>
                  </li>
                </ul>
              </nav>

              <div
                id="accountMenuBtnDiv"
                className="account-menu-btn-div"
                // style={{
                //   backgroundColor:
                //     (isHover || isAccountMenuActive) && signedIn
                //       ? "#257CE1"
                //       : "white",
                // }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleAccountClick}
                // onBlur={setIsAccountMenuActive(false)}
              >
                <button
                  tabindex="-1"
                  id="accountMenuBtn"
                  style={accountMenuBtnStyle}
                  onBlur={accountMenuBlur}
                >
                  {user === null
                    ? ""
                    : `HI, ${user.attributes[
                        "custom:first_name"
                      ].toUpperCase()}`}

                  <Chevron
                    id="navBarChevron"
                    className={
                      isAccountMenuActive
                        ? "chevron-icon rotate"
                        : "chevron-icon"
                    }
                    width={10}
                    style={{fill: isHover || isAccountMenuActive ? "white" : "black", transition: "fill 0.2s linear"}}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="accountMenuDiv"
        className="account-menu-div"
        style={{
          maxHeight: signedIn && isAccountMenuActive ? "125px" : "0px",
          width: menuWidth(),
        }}
      >
        <button
          tabindex="-1"
          className="account-menu-dropdown-item"
          onMouseDown={handleAccountSettingsClick}
        >
          ACCOUNT
        </button>
        <button
          className="account-menu-dropdown-item"
          onMouseDown={signOut}
          tabindex="-1"
        >
          SIGN OUT
        </button>
      </div>
    </>
  );
}

export default NavBar;
